import React, { useState } from "react";
import image from "../img/home.jpg";
import image1 from "../img/5.jpg";
import image11 from "../img/1about_us.jpg";
import image2 from "../img/yuyuyuu.jpg";
import image5555 from "../img/oioioioioio.jpg";
import image55555 from "../img/handwasing-3.jpeg.jpg";
import image6 from "../img/handwasing-5.jpg";
import image7 from "../img/handwasing-4.jpeg";
import image333 from "../img/222222222.jpg";
import image4444 from "../img/3617179_32c53f781b.jpg";
import audio from "../audio/Scrub-a-Dub.mp3";
import audio1 from "../audio/Handwashing Fun (1).mp3";
import imagee1 from "../img/wtd1.jpg";
import imagee2 from "../img/wtd2.jpg";
import imagee3 from "../img/wtd3.jpg";
import image9 from "../img/Untitled-1.png"
import imagee4 from "../img/wtd4.jpg";
import imagee5 from "../img/wtd5.jpg";
import imagee6 from "../img/wtd6.jpg";
import imagee7 from "../img/wtd7.jpg";
import imagep from "../img/pledge.jpg";
import imageq from "../img/jjj.jpg";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";


const Content = () => {
  const [activeTab, setActiveTab] = useState("active");
  const navigate = useNavigate();


  const handleNavigation = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/quize");
    } else {
      navigate("/login");
    }
  };
  const tabs = [
    { id: "active", title: "Chapter 1" },
    { id: "pending", title: "Chapter 2" },
    { id: "paused", title: "Video Show" },
    { id: "chapter-4", title: "Pledge" },

  ];

  const content = {
    active: (
      <>
        <div className="rounded-lg shadow-md">
          <div className="tab-page active bg-white p-5 ">
            <img
              src={image1}
              className="h-96 w-full object-cover rounded-lg"
              alt="Introduction to Handwashing"
            />
            <h1 className="text-2xl font-bold pt-5">
              Introduction to Handwashing
            </h1>
            <p>
              Friends, washing our hands is a super important part of our daily
              routine. When we wash our hands, we're keeping ourselves clean and
              healthy. Handwashing helps us get rid of those tiny germs that
              stick to our skin and can make us sick.
            </p>
            <h1 className="text-2xl font-bold">Why is it Important?</h1>
            <p>
              Imagine you’re playing outside and your hands get all dirty. If
              you eat with those hands, those germs can get into your tummy and
              make you feel unwell. That’s why we should always wash our hands
              before eating, after using the bathroom, and whenever they get
              dirty.
            </p>
            <h1 className="text-2xl font-bold">
              Story: 'Bebo and the Magic Soap'
            </h1>
            <p>
              Once upon a time, there was a little girl named Bebo who loved
              playing all day long. She forgot to wash her hands. One day, Bebo
              got sick. Her grandma told her a story about magic soap. Grandma
              said, "This soap has magic powers. Whenever you wash your hands
              with it, all the nasty germs will disappear, and you’ll stay
              healthy." Bebo was thrilled and started washing her hands with
              soap every day. From then on, she never got sick again.
            </p>
            <h1 className="text-2xl font-bold">
              Activity: 'Magic Handwashing Song'
            </h1>
            <p>
              Now, let’s sing a fun song that will teach us the steps to wash
              our hands properly.
            </p>
            <audio controls className="w-full">
              <source src={audio} type="audio/ogg" />
            </audio>
            <strong className="text-[#bb0000]">Song:</strong> "Get some soap,
            lather up, Mix your hands in water, scrub-a-dub! Rub for 20 seconds,
            don’t rush, Then rinse off the soap, that’s a must!"
          </div>

          <div className="tab-page text-white p-5 " data-tab-page="pending">
            <h1 className="text-2xl font-bold text-black">
              Why Handwashing is Important
            </h1>
            <p className="text-black">
              We can't see them, but germs and bacteria are all around us. These
              tiny organisms can live on surfaces, in the air, and even on our
              skin. While some bacteria are good for us, others can cause
              illnesses like colds, flu, and stomach bugs.
            </p>
            <img
              src={image2}
              alt=""
              className="tab-page-image rounded-lg my-4"
            />
            <h1 className="text-2xl font-bold text-black">
              How Do Germs Spread?
            </h1>
            <p className="text-black">
              Germs spread easily, especially through our hands. Imagine you're
              playing outside, and you touch the ground or a dirty toy. Germs
              from those surfaces stick to your hands. If you then touch your
              face, eat food, or touch other objects, those germs can spread to
              you and others.
            </p>
            <h1 className="text-2xl font-bold text-black pt-5">
              Real-Life Examples
            </h1>
            <p className="text-black">
              <strong className="text-[#bb0000]"> Playing Outside:</strong>{" "}
              After playing in the garden, your hands may be covered with dirt
              and germs. Washing your hands removes these germs, preventing you
              from getting sick.
            </p>
            <p className="text-black">
              <strong className="text-[#bb0000]"> Eating Food:</strong> When you
              eat with unwashed hands, germs can enter your mouth and make you
              ill. Washing hands before meals is a simple way to stay healthy.
            </p>
            <p className="text-black">
              <strong className="text-[#bb0000]"> Using the Bathroom:</strong>{" "}
              Bathrooms are places where germs thrive. Washing hands after using
              the toilet stops these germs from spreading to other parts of your
              home.
            </p>
            <h1 className="text-2xl font-bold text-black pt-5">
              How Does Handwashing Help?
            </h1>
            <p className="text-black">
              Washing hands with soap and water removes germs effectively. Soap
              breaks down the oils on our skin that trap germs, while the
              scrubbing action helps wash them away. Proper handwashing
              significantly reduces the risk of infections.
            </p>
            <h1 className="text-2xl font-bold text-black mt-3">
              The Science Behind Handwashing
            </h1>
            <p className="text-black">
              Studies show that regular handwashing can reduce the number of
              people who get sick with diarrhea by 31% and respiratory illnesses
              by 21%. It's a simple, yet powerful, action that keeps you and
              others around you healthy.
            </p>
            <h1 className="text-2xl font-bold text-black mt-3">
              Fun Activity: Germ Detective
            </h1>
            <p className="text-black">
              Pretend you’re a germ detective! Use a magnifying glass (real or
              imaginary) to spot where germs might be hiding in your hands.
              Then, wash your hands thoroughly and see how sparkling clean they
              become!
            </p>
            <img
              src={image11}
              className="h-96 w-full object-cover rounded-lg mt-5"
              alt="Introduction to Handwashing"
            />
          </div>

          <div
            className="tab-page p-5 rounded-lg shadow-md"
            data-tab-page="paused"
          >
            <h1 className="text-2xl font-bold">When to Wash Hands</h1>
            <p className="text-lg">
              Knowing when to wash your hands is just as important as knowing
              how to wash them. Here are some key times when handwashing is a
              must:
            </p>


            <div className="flex justify-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8 m-5">
                <img
                  src={imagee1}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
                <img
                  src={imagee2}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
                <img
                  src={imagee3}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
                <img
                  src={imagee4}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
                <img
                  src={imagee5}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
                <img
                  src={imagee6}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
                <img
                  src={imagee7}
                  className="h-80 w-80 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out object-contain mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    pending: (
      <>
        <div className="rounded-lg shadow-md">
          <div
            className="tab-page p-5 "
            data-tab-page="chapter-4"
          >
            <img
              src={image4444}
              className="h-96 w-full object-contain rounded-lg"
              alt="Introduction to Handwashing"
            />
            <h1 className="text-2xl font-bold mt-3">Steps to Proper Handwashing</h1>
            <p className="text-lg">
              Washing your hands the right way is key to getting rid of those
              pesky germs. Follow these steps to make sure your hands are
              squeaky clean!
            </p>
            <p className="text-lg">
              <strong className="text-[#bb0000]">1. Wet Your Hands:</strong> Turn on the tap and wet your
              hands with clean, running water. Remember to get your hands
              completely wet.
            </p>
            <p className="text-lg">
              <strong className="text-[#bb0000]">2. Apply Soap:</strong> Take some soap and rub it all over
              your hands, creating a lather.
            </p>
            <p className="text-lg">
              <strong className="text-[#bb0000]">3. Scrub for 20 Seconds:</strong> Scrub your hands for at
              least 20 seconds. Sing the "Happy Birthday" song twice to keep
              track of time.
            </p>
            <p className="text-lg">
              <strong className="text-[#bb0000]">4. Rinse:</strong> Rinse your hands under clean, running
              water. Make sure to remove all the soap.
            </p>
            <p className="text-lg">
              <strong className="text-[#bb0000]">5. Dry:</strong> Use a clean towel or air dryer to dry
              your hands completely.
            </p>
            <h2 className="text-2xl font-bold pt-5 ">Magic Handwashing Song</h2>
            <p>
              To make handwashing fun and to help you remember the steps, let's
              sing this magic song while scrubbing your hands!
            </p>
            <strong className="text-[#bb0000]">Song:</strong> (To the tune of
            "Twinkle, Twinkle, Little Star") "Water, soap, and lots of fun,
            scrub your hands till you’re all done. Front and back, between each
            one, under nails, and thumbs are done. Rinse and dry, now smile and
            cheer, Healthy hands throughout the year!"
            <audio controls className="w-full my-2">
              <source src={audio1} type="audio/ogg" />
            </audio>
          </div>
<div className="text-black font-extrabold flex justify-center text-center text-3xl mt-5">Healthy Habits</div>
          <div
            className="tab-page p-5 "
            data-tab-page="chapter-5"
          >
            <h1 className="text-2xl font-bold">Brushing Teeth</h1>
            <p>
              Brushing your teeth is essential for keeping your mouth healthy
              and your smile bright. It helps remove food particles and plaque
              that can cause cavities and gum disease. Brush your teeth twice a
              day—once in the morning and once before bed. Don’t forget to use
              toothpaste with fluoride and replace your toothbrush every 3
              months. Flossing is also important to clean between your teeth
              where a brush can't reach.
            </p>
            <strong className="text-[#bb0000]">Fun Fact:</strong> Your toothbrush should be able to cover
            two teeth at a time for effective cleaning!
            <h1 className="text-2xl font-bold mt-3">Taking a Bath</h1>
            <p>
              Bathing daily is crucial for keeping your body clean and fresh. It
              removes dirt, sweat, and germs that accumulate on your skin
              throughout the day. Use soap and water to scrub your entire body,
              paying special attention to your armpits, feet, and groin area.
              After bathing, make sure to dry yourself properly to avoid skin
              infections.
            </p>
            <strong className="text-[#bb0000]">Tip:</strong> Make bath time fun by using bath toys or
            singing a favorite song!
            <img
              src={image5555}
              className="h-96 my-5 w-full object-cover rounded-lg"
              alt="Introduction to Handwashing"
            />
            <h1 className="text-2xl font-bold">Eating Healthy</h1>
            <p>
              Eating a balanced diet filled with fruits, vegetables, proteins,
              and whole grains is vital for your overall well-being. Healthy
              eating gives your body the nutrients it needs to grow, stay
              strong, and fight off illnesses. Avoid too much junk food, sugary
              snacks, and soda. Drinking plenty of water is also important to
              stay hydrated.
            </p>
            <strong className="text-[#bb0000]">Healthy Plate:</strong> Imagine
            your plate as a rainbow. The more colorful it is, the healthier
            you’re eating!
            <h1 className="text-2xl font-bold mt-3">
              Connecting Habits with Well-Being
            </h1>
            <p>
              {" "}
              Good habits like brushing your teeth, taking regular baths, and
              eating nutritious food not only keep you healthy but also make you
              feel good. When you maintain these habits, you’re more likely to
              have more energy, feel happier, and do better in school and
              activities. Cleanliness and good hygiene also help you feel
              confident and ready to take on the world!
            </p>
            <h1 className="text-2xl font-bold mt-3">Activity: Habit Tracker</h1>
            <p>
              Create a weekly habit tracker to keep a check on these healthy
              habits. Draw a chart with columns for each day of the week and
              rows for different habits like brushing teeth, taking a bath, and
              eating fruits. Use stickers or colored markers to track your
              progress.
            </p>
        
          </div>

          <div
            className="tab-page p-5 rounded-lg shadow-md"
            data-tab-page="chapter-6"
          >
            <h1 className="text-2xl font-bold">
              Role of Cleanliness in Staying Healthy
            </h1>
            <p>
              Cleanliness is key to staying healthy. When we keep ourselves and
              our surroundings clean, we reduce the chance of coming into
              contact with germs that can cause diseases.
            </p>
            <img
              src={image6}
              alt="Importance of Drinking Water"
              className=" h-96 w-screen tab-page-image rounded-lg my-4"
            />
            <h2 className="text-xl font-bold ">Personal Cleanliness</h2>
            <ul className="">
              <li>
                <strong className="text-[#bb0000]">Washing Hands:</strong> Washing hands regularly,
                especially before eating and after using the bathroom, removes
                germs and prevents them from entering our body.
              </li>
              <li>
                <strong className="text-[#bb0000]">Brushing Teeth:</strong> Brushing teeth twice a day
                keeps your mouth clean and prevents cavities and gum diseases.
              </li>
              <li>
                <strong className="text-[#bb0000]">Bathing Daily:</strong> Taking a bath every day washes
                away dirt and germs, keeping our skin clean and healthy.
              </li>
            </ul>
            <h2 className="text-xl font-bold pt-5">
              Environmental Cleanliness
            </h2>
            <ul className="">
              <li>
                <strong className="text-[#bb0000]">Keeping Surroundings Clean:</strong> Clean living
                spaces, like our homes and schools, help reduce the number of
                germs. Regular cleaning of surfaces prevents germs from
                spreading.
              </li>
              <li>
                <strong className="text-[#bb0000]">Proper Waste Disposal:</strong> Throwing garbage in bins
                and disposing of waste properly keeps our environment clean and
                reduces the risk of infections.
              </li>
              <li>
                <strong className="text-[#bb0000]">Clean Drinking Water:</strong> Drinking clean, safe
                water prevents waterborne diseases. Always make sure the water
                you drink is filtered or boiled.
              </li>
            </ul>
            <h2 className="text-xl font-bold pt-5">
              How Cleanliness Prevents Disease
            </h2>
            <p>
              When we maintain good personal and environmental hygiene, we
              create a barrier that stops germs from spreading. Here’s a simple
              flowchart showing this process:
            </p>

            <img
              src={image9}
              alt="Importance of Drinking Water"
              className=" h-screen w-screen tab-page-image rounded-lg my-10"
            />

            {/* <p>
              Start ➡️ Maintain Personal Cleanliness ➡️ Reduce Germs on Body ➡️
              Prevent Entry of Germs ➡️ Stay Healthy ⬇️ Environmental
              Cleanliness ⬇️ Reduce Germs in Living Spaces ⬇️ Prevent Germ
              Spread ⬇️ Healthy Community
            </p> */}
            <p>
              By following these steps, we can make sure we stay healthy and
              help others stay healthy too. Cleanliness is a simple but powerful
              tool in the fight against diseases. Let's keep ourselves and our
              surroundings clean and enjoy a healthy life!
            </p>
          </div>
        </div>
      </>
    ),
    paused: (
<>


<div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 space-x-2">

      <div className="tab-content">
        <div className="font-medium py-1">Hindi Video</div>
        <div className="responsive-iframe-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/xXpIZVu9ZOM?si=s6a_VDqPTpC4oEpZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          
        
        </div>
      </div>

      <div className="tab-content gap-20">
        
      <div className="font-medium py-1">English Video</div>
        <div className="responsive-iframe-container object-cover">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/ohLwqQJYKNw?si=jc4U9cbRVvHwYLcQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
        </>
  
    ),
    
    "chapter-4": (
      <>
        {/* <div
          className="tab-page p-5 rounded-lg shadow-md"
          data-tab-page="chapter-7"
        >
        
          <h1 className="text-2xl font-bold pt-5">
            Final Activity: Make a Cleanliness Pledge
          </h1>
          <p>Let’s make a pledge to keep up our good habits.</p>
          <div className="mx-32">
            <img
              src={imagep}
              alt="Physical Activity"
              className=" rounded-lg my-4 h-96 w-screen "
            />
          </div>
          <p>
            Congratulations on completing the journey of learning about
            cleanliness and hygiene. Now, go out there and use your new skills
            to make the world a cleaner, healthier place! 🚀
          </p>
          <div className="relative my-10">
            <img
              src={imageq}
              alt="Physical Activity"
              className="rounded-lg h-screen w-screen object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center ">
              <div className="text-5xl text-white font-bold text-center  bg-opacity-70 px-4 py-2 rounded-lg">
                Now, Time To Get{" "}
                <span
                  className="text-[#FFDA00] cursor-pointer"
                  onClick={() => {
                    navigate("/quize");
                  }}
                >
                  Certificate
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div
  className="tab-page p-5 rounded-lg shadow-md"
  data-tab-page="chapter-7"
>
  <h1 className="text-2xl font-bold pt-5 text-center sm:text-3xl lg:text-4xl">
    Final Activity: Make a Cleanliness Pledge
  </h1>
  <p className="text-center sm:text-lg lg:text-xl">Let’s make a pledge to keep up our good habits.</p>
  <div className="mx-auto max-w-full sm:max-w-2xl lg:max-w-4xl">
    <img
      src={imagep}
      alt="Physical Activity"
      className="rounded-lg my-4 h-96 w-full sm:h-72 lg:h-96"
    />
  </div>
  <p className="text-center sm:text-lg lg:text-xl">
    Congratulations on completing the journey of learning about cleanliness and hygiene. Now, go out there and use your new skills to make the world a cleaner, healthier place! 🚀
  </p>
  <div className="relative my-10">
    <img
      src={imageq}
      alt="Physical Activity"
      className="rounded-lg h-auto w-full object-cover"
    />
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="text-4xl sm:text-5xl text-white font-bold text-center bg-opacity-70 px-4 py-2 rounded-lg">
        Time to complete the quize <br/> and get the {" "}
        {/* <span
          className="text-[#FFDA00] cursor-pointer"
          onClick={() => {
            navigate("/quize");
          }}
        >
          Certificate
        </span> */}
        <span
          className="text-[#FFDA00] cursor-pointer"
          onClick={handleNavigation}
        >
          Certificate
        </span>
      </div>
    </div>
  </div>
</div>

      </>
    ),
 
  };

  return (
    <>
      <div className="relative ">
        <div className="absolute  left-0 right-0 z-10 text-white">
          <Navbar />
        </div>

        <img src={image} className="w-screen" />
      </div>
      <div className="container mx-auto p-5">

        <div className="tabs flex justify-center space-x-5 mb-5">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-4 py-2 rounded-lg ${
                activeTab === tab.id
                  ? "bg-[#bb0000] text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div>{content[activeTab]}</div>


        
      </div>
    </>
  );
};

export default Content;
