import React from "react";
import tabletImage from "../img/home 1.png";
import tablet from "../img/cnc photo.png";
import backgroundImage from "../img/home2.png"
const Intro = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-center bg-white py-10 px-5 md:px-20">
        <div className="md:w-1/2 text-center md:text-left mb-10 md:mb-0">
          <h2 className="text-red-500 text-3xl font-bold mb-4">
          Project Introduction
          </h2>
          <p className="text-gray-700 leading-relaxed">
           
            Welcome to the "Clean Hands, Happy Life" portal, a dedicated initiative of The Bharat Scouts and Guides to promote handwashing and behavioural change among children. This project aims to educate and inspire young minds on the importance of hygiene through engaging activities, interactive resources and quizzes. An <span className=" text-red-600 font-bold" > e-certificate </span> will be generated on completing all the questions. By embracing these practices, we can ensure a healthier, happier future for our young minds and communities.
          </p>
        </div>

        <div className="md:w-1/2 flex justify-center">
          <div className="relative">
            <img
              src={tabletImage}
              alt="Tablet showcasing handwashing quiz"
              className="h-80"
            />
            <div className="absolute top-0 left-0 w-full h-full"></div>
          </div>
        </div>
      </div>
      <div
      className="flex flex-col md:flex-row items-center justify-center bg-cover bg-center py-10 px-5 md:px-20"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="md:w-1/2 flex justify-center mb-10 md:mb-0">
        <div className="relative">
          <img
            src={tablet}
            alt="Tablet showcasing handwashing quiz"
            className="h-96"
          />
          <div className="absolute top-0 left-0 w-full h-full "></div>
        </div>
      </div>
      
      <div className="md:w-1/2 text-center md:text-left p-6 bg-white bg-opacity-80 rounded-lg">
        <h1 className="text-red-500 text-3xl font-bold mb-4">
          Dear Members,
        </h1>
        <p className="text-gray-700 leading-relaxed">
        I am happy to introduce "Clean Hands, Happy Life - A Youth portal to Handwashing and Healthy Habits." This portal will help our young members to learn about the importance of handwashing and other good habits in a simple and fulfilled way. As the Chief National Commissioner of the Bharat Scouts and Guides, I believe this programme will help our young members to stay healthy and make their communities better. Following the tips on this portal can help keep yourself and others safe and healthy. Learn, enjoy and remember to wash your hands!
        </p>
        <h1 className="text-black text-2xl font-bold my-4 ">
         Best Wishes!!
        </h1>
        <div className="flex justify-end">
          <div className="flex flex-col">
        <h1 className="font-medium">Dr. K. K. Khandelwal, IAS(R)</h1>
        <div className="font-medium">Chief National Commissioner</div>
        </div>
        </div>


      </div>
    </div>
    </>
  );
};

export default Intro;
