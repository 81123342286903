import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import logo from "../img/bsg_logo.png";
// import logo from '../img/big logo.webp'
import { GiHamburgerMenu } from 'react-icons/gi'; // Import React Icon
import Swal from 'sweetalert2';

const Navbar = () => {
  const [username, setUsername] = useState(null);
  const [showExtraContent, setShowExtraContent] = useState(false); // State to show additional content
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu toggle
  const navigate = useNavigate();
  const secretKey = 'dgsrhbhsdfhdhsrje'; 

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      const encryptedUsername = localStorage.getItem('userName');

      if (token && encryptedUsername) {
        const bytes = CryptoJS.AES.decrypt(encryptedUsername, secretKey);
        const decryptedUsername = bytes.toString(CryptoJS.enc.Utf8);
        setUsername(decryptedUsername);
      }
    } catch (error) {
      console.error("Decryption error:", error);
    }
  }, []);

  const handleIconClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      setShowExtraContent(!showExtraContent);
    } else {
      Swal.fire('Access Denied', 'Please log in to view this content.', 'warning');
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to log out?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log out',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        setUsername(null);
        setShowExtraContent(false);
        navigate('/');
      }
    });
  };

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="flex items-center justify-between p-4">
      <img src={logo} alt="Logo" className="w-72" />

      {/* Hinderbug Icon (Hamburger menu for mobile view) */}
      <div className="md:hidden cursor-pointer" onClick={toggleMobileMenu}>
        <GiHamburgerMenu size={30} />
      </div>

      {/* Desktop Navbar */}
      <nav className="hidden md:flex space-x-6 text-lg">
        <Link to="/" className="transition duration-300">
          Home
        </Link>
        <Link to="/content" className="transition duration-300">
          Let's Begin
        </Link>
        {username ? (
          <>
             {/* <div className="cursor-pointer" onClick={() => { navigate('/quize'); }}>Quiz</div> */}
            {/* <span className="transition duration-300">{username}</span> */}
          
            <div
  className="block mb-4 cursor-pointer text-black bg-yellow-300 px-2 rounded-lg font-medium animate-blink"
  onClick={() => { navigate('/quize'); }}
>
  Quiz
</div>
<span className="block mb-4 text-black font-medium ">
  {username}
</span>

<button
              onClick={handleLogout}
              className="transition duration-300 bg-red-500 rounded-md py-1 px-2 text-white"
            >
              Logout
            </button>
          </>
        ) : (
          <>
            <Link to="/login">
              <div className="transition duration-300 bg-red-500 rounded-md py-1 px-2 text-white">
                Login
              </div>
            </Link>
            <Link to="/register">
              <div className="transition duration-300 bg-red-500 rounded-md py-1 px-2 text-white">
                Register
              </div>
            </Link>
          </>
        )}
      </nav>

      {/* Mobile Navbar - Toggle content when menu is open */}
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-lg p-4 h-screen">
          <Link to="/" className="block mb-4 text-black font-medium">Home</Link>
          <Link to="/content" className="block mb-4 text-black font-medium">Let's Begin</Link>
          {username ? (
            <>
              {/* <div className="block mb-4 cursor-pointer text-black font-medium" onClick={() => { navigate('/quize'); }}>Quiz</div>
              <span className="block mb-4 text-black font-medium">{username}</span> */}

<div
  className="block mb-4 cursor-pointer bg-yellow-300 rounded-md px-2 text-black font-medium animate-blink"
  onClick={() => { navigate('/quize'); }}
>
  Quiz
</div>
<span className="block mb-4 text-black font-medium ">
  {username}
</span>

              <button
                onClick={handleLogout}
                className="transition duration-300 bg-red-500 rounded-md py-1 px-2 text-white"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="block mb-4">
                <div className="transition duration-300 bg-red-500 rounded-md py-1 px-2 text-white">
                  Login
                </div>
              </Link>
              <Link to="/register" className="block mb-4">
                <div className="transition duration-300 bg-red-500 rounded-md py-1 px-2 text-white">
                  Register
                </div>
              </Link>
            </>
          )}
        </div>
      )}

      {/* Conditionally rendered content based on token */}
      {showExtraContent && (
        <div className="p-4 bg-gray-100 rounded-lg shadow-lg">
          <p>Welcome back, {username}! Here’s some exclusive content for logged-in users.</p>
          {/* Add more content here as needed */}
        </div>
      )}
    </div>
  );
};

export default Navbar;
