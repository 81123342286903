import React from 'react'
import Carousel from './Carousel'
import Intro from './Intro'
import Footer from './Footer'

const Home = () => {
  return (
   <>
   <Carousel/>
   <Intro/>
 
   </>
  )
}

export default Home