import React, { useState } from "react";
import Swal from "sweetalert2";
import Navbar from "./Navbar";

const Register = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    mobileNumber: "",
    BsgUid: "",
    BsgState: "",
    dob: "",
    whatsapp: "",
    gender: "",
    section: "",
    houseNo: "",
    streetName: "",
    village: "",
    pincode: "",
    state: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    // Custom validation for each field
    if (!formData.name) formErrors.name = "Full Name is required";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      formErrors.email = "Valid Email is required";

    if (
      !formData.password ||
      formData.password.length < 8 ||
      !/[A-Z]/.test(formData.password) ||
      !/[a-z]{2,}/.test(formData.password) ||
      !/[0-9]{2,}/.test(formData.password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)
    ) {
      formErrors.password =
        "Password must be at least 8 characters and include one uppercase letter,  lowercase letters, digits, and one special character.";
    }

    if (!formData.mobileNumber || !/^[0-9]{10,15}$/.test(formData.mobileNumber))
      formErrors.mobileNumber = "Enter a valid Mobile Number";
    // if (!formData.BsgUid) formErrors.BsgUid = "BSG Uid is required";
    if (!formData.BsgState) formErrors.BsgState = "BSG State is required";
    if (!formData.dob) formErrors.dob = "Date of Birth is required";
    if (!formData.whatsapp || !/^[0-9]{10,15}$/.test(formData.whatsapp))
      formErrors.whatsapp = "Enter a valid WhatsApp Number";
    if (!formData.gender) formErrors.gender = "Gender is required";
    // if (!formData.section) formErrors.section = "Section/Rank is required";
    if (!formData.houseNo) formErrors.houseNo = "Address Line 1 is required";
    if (!formData.pincode || !/^[0-9]{6}$/.test(formData.pincode))
      formErrors.pincode = "Enter a valid 6-digit Pincode";
    if (!formData.state) formErrors.state = "State is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    const {
      name,
      email,
      password,
      mobileNumber,
      BsgUid,
      BsgState,
      dob,
      whatsapp,
      gender,
      section,
      houseNo,
      streetName,
      village,
      pincode,
      state,
    } = formData;

    const address = `${houseNo} ${streetName} ${village} ${pincode} ${state}`;
    setIsSubmitting(true);
    try {
      const response = await fetch(
        "https://backendhandwashing.bsgindia.shop/api/q1/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            password,
            mobileNumber,
            BsgUid,
            BsgState,
            dob,
            address,
            whatsappNumber: whatsapp,
            gender,
            section,
            state,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Registration Successful",
          text: "You have successfully registered!",
          confirmButtonText: "OK",
        });
        setFormData({
          name: "",
          email: "",
          password: "",
          mobileNumber: "",
          BsgUid: "",
          BsgState: "",
          dob: "",
          whatsapp: "",
          gender: "",
          section: "",
          houseNo: "",
          streetName: "",
          village: "",
          pincode: "",
          state: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: data.message || "Something went wrong. Please try again.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
        text: "An error occurred while submitting the form. Please try again.",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center min-h-screen  bg-gray-100">
        <div className="bg-white shadow-lg rounded-lg p-10 w-full mt-3 mb-3 max-w-2xl mx-5">
          <h1 className="text-3xl font-bold text-center text-red-600 mb-8 uppercase tracking-wide">
            Registration Form
          </h1>
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 sm:grid-cols-2 gap-6"
          >
            <div>
              <label
                htmlFor="name"
                className="block font-semibold text-gray-700 mb-1"
              >
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                placeholder="Enter your full name"
                onChange={handleChange}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="BsgUid"
                className="block font-semibold text-gray-700 mb-1"
              > 
                BSG UID 
              </label>
              <input
                type="text"
                id="BsgUid"
                name="BsgUid"
                value={formData.BsgUid}
                placeholder="Enter your BSG UID"
                onChange={handleChange}
                // required
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              <span className="text-red-500  text-sm">(not mandotary for non BSG members)</span>
              {errors.BsgUid && (
                <p className="text-red-500 text-sm">{errors.BsgUid}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block font-semibold text-gray-700 mb-1"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                placeholder="Enter your email"
                onChange={handleChange}
                required
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="password"
                className="block font-semibold text-gray-700 mb-1"
              >
                Password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                placeholder="Enter your password"
                onChange={handleChange}
                required
                maxLength={8}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="mobileNumber"
                className="block font-semibold text-gray-700 mb-1"
              >
                Mobile Number <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                value={formData.mobileNumber}
                placeholder="Enter your mobile number"
                onChange={handleChange}
                required
                pattern="[0-9]{10,15}"
                maxLength={10}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.mobileNumber && (
                <p className="text-red-500 text-sm">{errors.mobileNumber}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="whatsapp"
                className="block font-semibold text-gray-700 mb-1"
              >
                WhatsApp Number <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                id="whatsapp"
                name="whatsapp"
                value={formData.whatsapp}
                placeholder="Enter your WhatsApp number"
                onChange={handleChange}
                required
                pattern="[0-9]{10,15}"
                maxLength={10}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.whatsapp && (
                <p className="text-red-500 text-sm">{errors.whatsapp}</p>
              )}
            </div>
           

            <div>
              <label
                htmlFor="BsgState"
                className="block font-semibold text-gray-700 mb-1"
              >
                BSG State/Revenue State<span className="text-red-500">*</span>
              </label>
              <select
                id="BsgState"
                name="BsgState"
                value={formData.BsgState}
                onChange={handleChange}
                required
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              >
                <option value="">Select Your State</option>
                <option value="NHQ">NHQ</option>
                <option value="BIHAR">Bihar</option>
                <option value="CENTRAL RAILWAY">Central Railway</option>
                <option value="CHANDIGARH (U.T.)">Chandigarh (U.T.)</option>
                <option value="CHHATTISGARH">Chhattisgarh</option>
                <option value="DADAR NAGAR HAVELI">Dadar Nagar Haveli</option>
                <option value="DELHI">Delhi</option>
                <option value="EASTERN RAILWAY">Eastern Railway</option>
                <option value="EAST CENTRAL RAILWAY">
                  East Central Railway
                </option>
                <option value="EAST COAST RAILWAY">East Coast Railway</option>
                <option value="DAV COLLEGE M. C.">
                  DAV College M.C.
                </option>
                <option value="NATIONAL DST ASN">
                  National Dst. Asn.
                </option>
                <option value="U.T. OF DAMAN AND DIU">
                  U.T. of Daman and Diu
                </option>
                <option value="WEST CENTRAL RAILWAY">
                  West Central Railway
                </option>
                <option value="WESTERN RAILWAY">Western Railway</option>
                <option value="WEST BENGAL">West Bengal</option>
                <option value="UTTAR PRADESH">Uttar Pradesh</option>
                <option value="UTTARAKHAND">Uttarakhand</option>
                <option value="TRIPURA">Tripura</option>
                <option value="TELANGANA">Telangana</option>
                <option value="TAMIL NADU">Tamil Nadu</option>
                <option value="S W RAILWAY">
                  S W Railway
                </option>
                <option value="S E C RAILWAY">
                  S E C Railway
                </option>
                <option value="S E RAILWAY">
                  S E Railway
                </option>
                <option value="S C RAILWAY">
                  S C Railway
                </option>
                <option value="SOUTHERN RAILWAY">Southern Railway</option>
                <option value="SIKKIM">Sikkim</option>
                <option value="RAJASTHAN">Rajasthan</option>
                <option value="PUNJAB">Punjab</option>
                <option value="PUDUCHERRY">Puducherry</option>
                <option value="ODISHA">Odisha</option>
                <option value="N W RAILWAY">
                  N W Railway
                </option>
                <option value="N F RAILWAY">
                  N F Railway
                </option>
                <option value="N E RAILWAY">
                  N E Railway
                </option>
                <option value="N C RAILWAY">
                  N C Railway
                </option>
                <option value="NORTHERN RAILWAY">Northern Railway</option>
                <option value="NVS">
                  NVS
                </option>
                <option value="NAGALAND">Nagaland</option>
                <option value="MIZORAM">Mizoram</option>
                <option value="MEGHALAYA">Meghalaya</option>
                <option value="MANIPUR">Manipur</option>
                <option value="MAHARASHTRA">Maharashtra</option>
                <option value="MADHYA PRADESH">Madhya Pradesh</option>
                <option value="KERALA">Kerala</option>
                <option value="KVS">
                  KVS
                </option>
                <option value="KARNATAKA">Karnataka</option>
                <option value="JHARKHAND">Jharkhand</option>
                <option value="JAMMU & KASHMIR">Jammu & Kashmir</option>
                <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                <option value="HARYANA">Haryana</option>
                <option value="GUJARAT">Gujarat</option>
                <option value="GOA">Goa</option>
                <option value="ASSAM">Assam</option>
              </select>
              
              {errors.BsgState && (
                <p className="text-red-500 text-sm">{errors.BsgState}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="dob"
                className="block font-semibold text-gray-700 mb-1"
              >
                Date of Birth <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.dob && (
                <p className="text-red-500 text-sm">{errors.dob}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="gender"
                className="block font-semibold text-gray-700 mb-1"
              >
                Gender <span className="text-red-500">*</span>
              </label>
              <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              >
                <option value="" disabled>
                  Select your gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="TRANSGENDER">Transgender</option>
                
              </select>
              {errors.gender && (
                <p className="text-red-500 text-sm">{errors.gender}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="section"
                className="block font-semibold text-gray-700 mb-1"
              >
                Section/Rank
              </label>
              <select
                id="section"
                name="section"
                value={formData.section}
                onChange={handleChange}
                // required

                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              >
                <option value="">Select Your Section</option>
                <option value="CUB">Cub</option>
                <option value="BULBUL">Bulbul</option>
                <option value="SCOUT">Scout</option>
                <option value="GUIDE">Guide</option>
                <option value="ROVER">Rover</option>
                <option value="RANGER">Ranger</option>
                <option value="UNIT LEADER">Unit Leader</option>
                <option value="TRAINER">Trainer</option>
                <option value="OTHER">Other</option>
              </select>
              <span className="text-red-500  text-sm">(not mandotary for non BSG members)</span>
              {errors.section && (
                <p className="text-red-500 text-sm">{errors.section}</p>
              )}
            </div>

            

            

            <div>
              <label
                htmlFor="houseNo"
                className="block font-semibold text-gray-700 mb-1"
              >
                Address Line 1 <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="houseNo"
                name="houseNo"
                value={formData.houseNo}
                placeholder="Enter your address"
                onChange={handleChange}
                required
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.houseNo && (
                <p className="text-red-500 text-sm">{errors.houseNo}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="streetName"
                className="block font-semibold text-gray-700 mb-1"
              >
                Address Line 2
              </label>
              <input
                type="text"
                id="streetName"
                name="streetName"
                value={formData.streetName}
                placeholder="Enter your street name"
                onChange={handleChange}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.streetName && (
                <p className="text-red-500 text-sm">{errors.streetName}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="village"
                className="block font-semibold text-gray-700 mb-1"
              >
                Address Line 3
              </label>
              <input
                type="text"
                id="village"
                name="village"
                value={formData.village}
                placeholder="Enter your village"
                onChange={handleChange}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.village && (
                <p className="text-red-500 text-sm">{errors.village}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="pincode"
                className="block font-semibold text-gray-700 mb-1"
              >
                Pincode <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                value={formData.pincode}
                placeholder="Enter your pincode"
                onChange={handleChange}
                required
                maxLength={6}
                pattern="[0-9]{6}"
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              />
              {errors.pincode && (
                <p className="text-red-500 text-sm">{errors.pincode}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="pincode"
                className="block font-semibold text-gray-700 mb-1"
              >
                Education Qualification<span className="text-red-500">*</span>
              </label>
              <select
                type="text"
                id="state"
                name="state"
                value={formData.state}
                placeholder="Enter your State"
                onChange={handleChange}
                className="mt-1 w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-400 shadow-sm"
              >
                <option value="" disabled>
                 Select Education Qualification
                </option>
                <option value="Primary">Primary</option>
                <option value="Secondary">Secondary</option>
                <option value="Higher Secondary">Higher Secondary</option>
                <option value="Under Graduate">Under Graduate</option>
                <option value="Graduate and Higher">Graduate and Higher</option>
              </select>
              {errors.state && (
                <p className="text-red-500 text-sm">{errors.state}</p>
              )}
            </div>
            <div className="sm:col-span-2 mt-8">
              <button
                type="submit"
                className="w-full bg-red-600 text-white font-semibold py-3 rounded-md shadow-md hover:bg-red-700 transition duration-200 focus:outline-none focus:ring-4 focus:ring-red-300"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="text-lg font-semibold animate-pulse">
                    Please wait...
                  </span>
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
